
import { defineComponent } from 'vue';
import { useStore } from '@/store';
import { ActionTypes } from '@/store/action-types';
import { ThreeDimensionalModel } from '@/models/three-dimensional-model';
import HttpExtendedResultAlert from '@/components/HttpExtendedResultAlert.vue';
import { HttpExtendedResult } from '@/services/http-extended-result';
import { getSizeAsString } from '@/services/format-tools';
import axios, { CancelTokenSource } from 'axios';
import CircularProgress from '@/components/CircularProgress.vue';
import { clickElement } from '@/services/dom-tools';
import DemoWarningBanner from '@/components/DemoWarningBanner.vue';

export default defineComponent({
  name: 'ModelUploadDialog',
  emits: ['closeclicked'],
  components: {
    HttpExtendedResultAlert,
    CircularProgress,
    DemoWarningBanner,
  },
  props: {
    model: {
      type: ThreeDimensionalModel,
      required: true
    },
  },

  setup() {
    const store = useStore();
    return { store };
  },

  beforeUnmount() {
    this.cancelToken?.cancel('Model upload dialog component unmount.');
  },

  data: () => ({
    selectedFile: undefined as File | undefined,
    fileName: '',
    fileSize: '',

    startTime: new Date(),
    progress: 0,
    averageSpeed: 'n/a',
    averageSpeedColor: 'green',
    remainingTime: 'n/a',
    displayProgressInfo: false,

    loading: false,
    cancelToken: undefined as CancelTokenSource | undefined,
    error: undefined as HttpExtendedResult | undefined,
  }),

  computed: {
    uploadDisabled(): boolean { return this.loading || this.selectedFile === undefined || this.progress === 100; },
    uploadInProgress(): boolean { return this.loading && this.progress < 100; },
    uploadProcessServer(): boolean { return this.loading && this.progress === 100; },
    uploadDone(): boolean { return this.loading == false && this.progress === 100; },
    authToken(): string | undefined { return this.store.state.jwt.data?.authToken; },
  },

  watch: {
    model() {
      this.initData();
    },
  },

  methods: {
    onUpload() {
      if (!this.authToken || !this.selectedFile)
        return;

      this.loading = true;
      this.cancelToken = axios.CancelToken.source();
      this.startTime = new Date();

      const formData = new FormData();
      formData.append("file", this.selectedFile as File);

      const payload = {
        modelId: this.model.id,
        formData: formData,
        progressCallback: this.onUploadProgress,
        cancelToken: this.cancelToken};

      this.store.dispatch(ActionTypes.UPLOAD_THREE_DIMENSIONAL_MODEL, payload).then(
        () => this.onUploadSuccess(),
        error => this.onUploadError(error));
    },

    onUploadProgress(event: ProgressEvent) {
      if (!event.lengthComputable)
        return;

      const totalLength = event.total;
      const currentLength = event.loaded;
      const currentTime = new Date();
      const duration = (currentTime.getTime() - this.startTime.getTime()) / 1000;
      const speed = currentLength / duration;
      const percentage = (currentLength * 100) / totalLength;
      const remainingTime = (totalLength - currentLength) / speed;

      this.setProgressInfo(percentage, duration, speed, remainingTime);
    },

    onUploadSuccess() {
      this.loading = false;
    },

    onUploadError(error: HttpExtendedResult) {
      this.loading = false;
      
      if (!error.isCancel)
        this.error = error;
    },

    onSelectFile() {
      clickElement('modelFileSelect');
    },

    onDropFile(event: DragEvent) {
      // Prevent default behavior (prevent file from being opened).
      event.preventDefault();

      const data = (event.dataTransfer as DataTransfer);

      if (data.items) {
        for (let nItem = 0; nItem < data.items.length; nItem++) {
          if (data.items[nItem].kind === 'file') {
            const file = data.items[nItem].getAsFile();
            if (file && this.isSupportedFileType(file)) {
              this.setSelectedFile(file);
            }
          }
        }
      }
    },

    onDragOverFile(event: Event) {
      // Prevent default behavior (prevent file from being opened).
      event.preventDefault();
    },

    onFileSelected(event: Event) {
      const files = (event.target as HTMLInputElement).files;

      if (files && files.length > 0) {
        this.setSelectedFile(files[0]);
      }
    },

    setSelectedFile(file: File) {
      this.selectedFile = file;
      this.fileName = file.name;
      this.fileSize = getSizeAsString(file.size);

      (document.getElementById('modelFileSelect') as HTMLInputElement).value = '';
    },

    isSupportedFileType(file: File): boolean {
      const extension = file.name.substring(file.name.lastIndexOf('.') + 1).toLowerCase();
      return (extension === 'ifc' || extension === 'obj' || extension === 'fbx');
    },

    onCloseButton() {
      clickElement('closeButton');
      this.cancelToken?.cancel('Model upload dialog component closed.');
      setTimeout(() => this.initData(), 1000);
      this.$emit('closeclicked');
    },

    setProgressInfo(percentage: number, duration: number, speed: number, remainingTime: number): void {
      this.progress = Math.round(percentage);

      if      (speed > 1024 * 1024) this.averageSpeed = (speed / 1024 / 1024).toFixed(1) + ' MB/s';
      else if (speed > 1024)        this.averageSpeed = (speed / 1024).toFixed(1) + ' KB/s';
      else                          this.averageSpeed = speed + ' B/s';

      if      (speed > 2 * 1024 * 1024) this.averageSpeedColor = '#34CE34';
      else if (speed > 1024 * 1024)     this.averageSpeedColor = 'orange';
      else                              this.averageSpeedColor = 'red';

      if      (remainingTime > 3600) this.remainingTime = (remainingTime / 3600).toFixed(1) + ' hours';
      else if (remainingTime > 60)   this.remainingTime = (remainingTime / 60).toFixed(0) + ' min.';
      else                           this.remainingTime = remainingTime.toFixed(0) + ' sec.';

      if (duration > 2)
        this.displayProgressInfo = true;
    },

    initData() {
      this.selectedFile = undefined;
      this.progress = 0;
      this.displayProgressInfo = false;
      this.error = undefined;
    },
  },
});
