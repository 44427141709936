
import { defineComponent } from 'vue';
import { useStore } from '@/store';
import { ActionTypes } from '@/store/action-types';
import { ThreeDimensionalModel } from '@/models/three-dimensional-model';
import { ProjectModel } from '@/models/project-model';
import { ListCardItem } from '@/models/list-card-item';
import MessageBox from '@/components/MessageBox.vue';
import HttpExtendedResultAlert from '@/components/HttpExtendedResultAlert.vue';
import { HttpExtendedResult } from '@/services/http-extended-result';
import { getImageInfoFromBlob, blobToBase64, base64ToBlob, resizeImage } from '@/services/image-tools';
import { getSizeAsString } from '@/services/format-tools';
import ModelUploadDialog from '@/components/ModelUploadDialog.vue';
import { ImageInfo, ImageSizeCssClass } from '@/models/image-info';
import { clickElement } from '@/services/dom-tools';

export default defineComponent({
  name: 'ThreeDimensionalModelForm',
  components: {
    MessageBox,
    HttpExtendedResultAlert,
    ModelUploadDialog,
  },
  props: {
    item: {
      type: ThreeDimensionalModel,
      required: true
    },
  },

  setup() {
    const store = useStore();
    return { store };
  },

  beforeUnmount() {
    this.clearThumbnail();
  },

  data: () => ({
    projectId: 0,
    name: '',
    description: undefined as string | undefined,
    
    thumbnailUpdated: false,
    thumbnailBlob: undefined as Blob | undefined,
    thumbnailUrl: undefined as string | undefined,
    thumbnailInfo: undefined as string | undefined,
    imageInfo: undefined as ImageInfo | undefined,

    loading: false,
    error: undefined as HttpExtendedResult | undefined,
  }),

  watch: {
    'item': {
      handler: function(newItem: ThreeDimensionalModel) {
        this.clearThumbnail();
        this.thumbnailBlob = undefined;

        this.projectId = newItem.projectId;
        this.name = newItem.name;
        this.description = newItem.description;

        if (newItem.thumbnail && newItem.thumbnail.length > 0)
          this.loadThumbnailFromBlob(base64ToBlob(newItem.thumbnail));
        
        this.thumbnailUpdated = false;
        this.error = undefined;
      },
      //deep: true,
      immediate: true
    },
  },

  computed: {
    editMode(): boolean { return this.item.id > 0; },
    saveDisabled(): boolean { return this.loading || (this.item.name == this.name && this.item.description == this.description && !this.thumbnailUpdated); },
    projectsList(): ListCardItem[] { return this.store.getters.projectsList; },
    projectName(): string {
      const project = this.getProjectById(this.projectId);      
      return project ? project.name : '(select project first)';
    },
    fileSize(): string { return this.item.mainFileSize ? getSizeAsString(this.item.mainFileSize) : ''; },
    thumbnailStyle(): ImageSizeCssClass { return this.calculateThumbnailSize(); },
  },

  methods: {
    async onSave() {
      this.loading = true;
      this.error = undefined;

      let thumbnailBase64 = this.item.thumbnail;

      if (this.thumbnailBlob)
        thumbnailBase64 = await blobToBase64(this.thumbnailBlob);

      const payload = new ThreeDimensionalModel(
        this.item.id,
        this.projectId,
        this.name,
        this.description,
        this.item.mainFilePath,
        this.item.mainFileType,
        this.item.mainFileSize,
        thumbnailBase64);

      if (this.editMode)
        this.update(payload);
      else
        this.add(payload);
    },

    onDelete() {
      clickElement('confirmDeleteModelShowButton');
    },

    async onDeleteValidate() {
      this.loading = true;
      this.error = undefined;

      this.store.dispatch(ActionTypes.DELETE_THREE_DIMENSIONAL_MODEL, this.item.id).then(
        status => this.parseDeleteStatus(status),
        error => this.parseResponse(undefined, error));
    },

    add(payload: ThreeDimensionalModel) {
      this.store.dispatch(ActionTypes.ADD_THREE_DIMENSIONAL_MODEL, payload).then(
        model => this.parseResponse(model, undefined),
        error => this.parseResponse(undefined, error));
    },

    update(payload: ThreeDimensionalModel) {
      this.store.dispatch(ActionTypes.UPDATE_THREE_DIMENSIONAL_MODEL, payload).then(
        model => this.parseResponse(model, undefined),
        error => this.parseResponse(undefined, error));
    },

    parseResponse(model?: ThreeDimensionalModel, error?: HttpExtendedResult) {
      this.loading = false;
      this.error = error;

      if (model) {
        this.$emit('savedone', model);

        if (this.editMode == false)
          clickElement('uploadModelButton', 500);
      }
    },

    parseDeleteStatus(status: boolean) {
      this.loading = false;

      if (status) {
        this.$emit('deletedone');
      }
      else {
        const result: HttpExtendedResult = { status: 500, title: 'An unknown error occurred!', isCancel: false };
        this.parseResponse(undefined, result);
      }
    },

    getProjectById(id: number): ProjectModel {
      return this.store.getters.getProjectById(id);
    },

    onDropPicture(event: DragEvent) {
      // Prevent default behavior (prevent file from being opened).
      event.preventDefault();

      const data = (event.dataTransfer as DataTransfer);

      if (data.items) {
        for (let nItem = 0; nItem < data.items.length; nItem++) {
          if (data.items[nItem].kind === 'file') {
            const file = data.items[nItem].getAsFile();
            if (file && this.isSupportedPictureType(file)) {
              this.setSelectedPicture(file);
            }
          }
        }
      }
    },

    onDragOverPicture(event: Event) {
      // Prevent default behavior (prevent file from being opened).
      event.preventDefault();
    },

    isSupportedPictureType(file: File): boolean {
      const extension = file.name.substring(file.name.lastIndexOf('.') + 1).toLowerCase();
      return (extension === 'jpg' || extension === 'jpeg' || extension === 'png');
    },

    onSelectThumbnailFile() {
      clickElement('thumbnailFileSelect');
    },

    onThumbailFileSelected(event: Event) {
      const files = (event.target as HTMLInputElement).files;

      if (files && files.length > 0) {
        const file = files[0];
        this.setSelectedPicture(file);
        (document.getElementById('thumbnailFileSelect') as HTMLInputElement).value = '';
      }
    },

    setSelectedPicture(file: File) {
      if (file.type == 'image/jpeg' || file.type == 'image/png') {
        resizeImage(file, 600, 600, 'image/jpeg', 0.5).then(blob => {
          this.thumbnailBlob = blob;
          this.loadThumbnailFromBlob(blob);
          this.thumbnailUpdated = true;
        });
      }
      else {
        alert('You can only select JPEG or PNG image files.');
      }
    },

    loadThumbnailFromBlob(blob: Blob) {
      this.clearThumbnail();

      this.thumbnailUrl = URL.createObjectURL(blob);
      this.displayThumbnailInfo(blob);
    },

    displayThumbnailInfo(blob: Blob) {
      getImageInfoFromBlob(blob).then(imageInfo => {
        this.imageInfo = imageInfo;
        this.thumbnailInfo = imageInfo.size.width + ' x ' + imageInfo.size.height + ' | ' + getSizeAsString(blob.size) + ' | ' + imageInfo.type.split('/')[1];
      });
    },

    clearThumbnail() {
      if (this.thumbnailUrl)
        URL.revokeObjectURL(this.thumbnailUrl);

      this.thumbnailUrl = undefined;
      this.thumbnailInfo = undefined;
    },

    calculateThumbnailSize(): ImageSizeCssClass {
      const maxImageSize = 400;

      if (this.imageInfo) {
        if (this.imageInfo.size.height < this.imageInfo.size.width) {
          // Landscape image.
          const scale = maxImageSize / this.imageInfo.size.width;
          const height = this.imageInfo.size.height * scale;
          return { width: maxImageSize + 'px', height: height + 'px' };
        }
        else {
          // Portrait image.
          const scale = maxImageSize / this.imageInfo.size.height;
          const width = this.imageInfo.size.width * scale;
          return { width: width + 'px', height: maxImageSize + 'px' };
        }
      }

      return { width: maxImageSize + 'px', height: maxImageSize + 'px' };
    },
  },
});
