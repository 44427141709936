<template>
  <div class="modal fade" id="modelUploadModal" tabindex="-1" role="dialog">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Upload 3D model</h5>
          <button type="button" class="close" aria-label="Close" @click.prevent="onCloseButton">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <DemoWarningBanner />
          <div class="d-flex justify-content-center">
            <h4>{{ model.name }}</h4>
          </div>

          <div class="d-flex w-100 mt-2">
            <div v-if="selectedFile" class="w-50">
              <p>File name</p>
              <p class="file-info">{{ fileName }}</p>
              <p>File size</p>
              <p class="file-info">{{ fileSize }}</p>
            </div>

            <div v-if="uploadInProgress" class="w-50">
              <CircularProgress :progress="progress" :radius="70" class="upload-progress" />
              <div v-if="displayProgressInfo" class="upload-progress-info mt-2">
                <div class="text-muted">Average speed: <span :style="{ color: averageSpeedColor }">{{ averageSpeed }}</span></div>
                <div class="text-muted">Estimated remaining time: {{ remainingTime }}</div>
              </div>
            </div>

            <div v-else-if="uploadProcessServer" class="w-50">
              <div class="d-flex justify-content-center mt-4"><font-awesome-icon icon="spinner" spin class="big-icon" /></div>
              <div class="text-center mt-4">Processing your 3D model on the server...</div>
            </div>

            <div v-else-if="uploadDone" class="w-50">
              <div class="d-flex justify-content-center mt-4"><font-awesome-icon icon="check" class="big-icon green" /></div>
              <div class="text-center mt-4"><strong>Done</strong></div>
            </div>

            <div v-else class="upload-button" :class="selectedFile ? 'w-50' : 'w-100'" @click="onSelectFile" @drop="onDropFile" @dragover="onDragOverFile">
              <div class="d-flex justify-content-center"><font-awesome-icon icon="file-upload" class="big-icon" /></div>
              <div class="d-flex justify-content-center mt-2">Drop a file, or click to select a file</div>
              <div class="d-flex justify-content-center mt-2"><small class="text-muted">IFC, FBX, or OBJ files. 512 Mo maximum size.</small></div>
            </div>
          </div>

          <HttpExtendedResultAlert v-if="error" v-bind:result="error" />
        </div>

        <div class="modal-footer">
          <input id="modelFileSelect" type="file" @change="onFileSelected" accept=".ifc, .fbx, .obj" style="display: none;">
          <button v-if="!uploadDone" type="button" class="btn btn-primary" @click.prevent="onUpload()" v-bind:disabled="uploadDisabled">
            <font-awesome-icon v-if="loading" icon="spinner" spin />
            Upload
          </button>
          <button type="button" class="btn btn-secondary" @click.prevent="onCloseButton">Close</button>
          <button id="closeButton" type="button" data-dismiss="modal" style="display: none;">Modal close</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useStore } from '@/store';
import { ActionTypes } from '@/store/action-types';
import { ThreeDimensionalModel } from '@/models/three-dimensional-model';
import HttpExtendedResultAlert from '@/components/HttpExtendedResultAlert.vue';
import { HttpExtendedResult } from '@/services/http-extended-result';
import { getSizeAsString } from '@/services/format-tools';
import axios, { CancelTokenSource } from 'axios';
import CircularProgress from '@/components/CircularProgress.vue';
import { clickElement } from '@/services/dom-tools';
import DemoWarningBanner from '@/components/DemoWarningBanner.vue';

export default defineComponent({
  name: 'ModelUploadDialog',
  emits: ['closeclicked'],
  components: {
    HttpExtendedResultAlert,
    CircularProgress,
    DemoWarningBanner,
  },
  props: {
    model: {
      type: ThreeDimensionalModel,
      required: true
    },
  },

  setup() {
    const store = useStore();
    return { store };
  },

  beforeUnmount() {
    this.cancelToken?.cancel('Model upload dialog component unmount.');
  },

  data: () => ({
    selectedFile: undefined as File | undefined,
    fileName: '',
    fileSize: '',

    startTime: new Date(),
    progress: 0,
    averageSpeed: 'n/a',
    averageSpeedColor: 'green',
    remainingTime: 'n/a',
    displayProgressInfo: false,

    loading: false,
    cancelToken: undefined as CancelTokenSource | undefined,
    error: undefined as HttpExtendedResult | undefined,
  }),

  computed: {
    uploadDisabled(): boolean { return this.loading || this.selectedFile === undefined || this.progress === 100; },
    uploadInProgress(): boolean { return this.loading && this.progress < 100; },
    uploadProcessServer(): boolean { return this.loading && this.progress === 100; },
    uploadDone(): boolean { return this.loading == false && this.progress === 100; },
    authToken(): string | undefined { return this.store.state.jwt.data?.authToken; },
  },

  watch: {
    model() {
      this.initData();
    },
  },

  methods: {
    onUpload() {
      if (!this.authToken || !this.selectedFile)
        return;

      this.loading = true;
      this.cancelToken = axios.CancelToken.source();
      this.startTime = new Date();

      const formData = new FormData();
      formData.append("file", this.selectedFile as File);

      const payload = {
        modelId: this.model.id,
        formData: formData,
        progressCallback: this.onUploadProgress,
        cancelToken: this.cancelToken};

      this.store.dispatch(ActionTypes.UPLOAD_THREE_DIMENSIONAL_MODEL, payload).then(
        () => this.onUploadSuccess(),
        error => this.onUploadError(error));
    },

    onUploadProgress(event: ProgressEvent) {
      if (!event.lengthComputable)
        return;

      const totalLength = event.total;
      const currentLength = event.loaded;
      const currentTime = new Date();
      const duration = (currentTime.getTime() - this.startTime.getTime()) / 1000;
      const speed = currentLength / duration;
      const percentage = (currentLength * 100) / totalLength;
      const remainingTime = (totalLength - currentLength) / speed;

      this.setProgressInfo(percentage, duration, speed, remainingTime);
    },

    onUploadSuccess() {
      this.loading = false;
    },

    onUploadError(error: HttpExtendedResult) {
      this.loading = false;
      
      if (!error.isCancel)
        this.error = error;
    },

    onSelectFile() {
      clickElement('modelFileSelect');
    },

    onDropFile(event: DragEvent) {
      // Prevent default behavior (prevent file from being opened).
      event.preventDefault();

      const data = (event.dataTransfer as DataTransfer);

      if (data.items) {
        for (let nItem = 0; nItem < data.items.length; nItem++) {
          if (data.items[nItem].kind === 'file') {
            const file = data.items[nItem].getAsFile();
            if (file && this.isSupportedFileType(file)) {
              this.setSelectedFile(file);
            }
          }
        }
      }
    },

    onDragOverFile(event: Event) {
      // Prevent default behavior (prevent file from being opened).
      event.preventDefault();
    },

    onFileSelected(event: Event) {
      const files = (event.target as HTMLInputElement).files;

      if (files && files.length > 0) {
        this.setSelectedFile(files[0]);
      }
    },

    setSelectedFile(file: File) {
      this.selectedFile = file;
      this.fileName = file.name;
      this.fileSize = getSizeAsString(file.size);

      (document.getElementById('modelFileSelect') as HTMLInputElement).value = '';
    },

    isSupportedFileType(file: File): boolean {
      const extension = file.name.substring(file.name.lastIndexOf('.') + 1).toLowerCase();
      return (extension === 'ifc' || extension === 'obj' || extension === 'fbx');
    },

    onCloseButton() {
      clickElement('closeButton');
      this.cancelToken?.cancel('Model upload dialog component closed.');
      setTimeout(() => this.initData(), 1000);
      this.$emit('closeclicked');
    },

    setProgressInfo(percentage: number, duration: number, speed: number, remainingTime: number): void {
      this.progress = Math.round(percentage);

      if      (speed > 1024 * 1024) this.averageSpeed = (speed / 1024 / 1024).toFixed(1) + ' MB/s';
      else if (speed > 1024)        this.averageSpeed = (speed / 1024).toFixed(1) + ' KB/s';
      else                          this.averageSpeed = speed + ' B/s';

      if      (speed > 2 * 1024 * 1024) this.averageSpeedColor = '#34CE34';
      else if (speed > 1024 * 1024)     this.averageSpeedColor = 'orange';
      else                              this.averageSpeedColor = 'red';

      if      (remainingTime > 3600) this.remainingTime = (remainingTime / 3600).toFixed(1) + ' hours';
      else if (remainingTime > 60)   this.remainingTime = (remainingTime / 60).toFixed(0) + ' min.';
      else                           this.remainingTime = remainingTime.toFixed(0) + ' sec.';

      if (duration > 2)
        this.displayProgressInfo = true;
    },

    initData() {
      this.selectedFile = undefined;
      this.progress = 0;
      this.displayProgressInfo = false;
      this.error = undefined;
    },
  },
});
</script>

<style lang="scss" scoped>
.upload-button {
  cursor: pointer;
  border-style: dashed;
  border-color: #aaa;
  padding: 1rem;
  border-radius: .5rem;
  border-width: 2px;
}

.upload-progress {
  margin: auto;
}

.upload-progress-info {
  font-size: .8rem;
}

.big-icon {
   font-size: 3rem;

   &.green {
     color: #34CE34;
   }
}

.file-info {
  font-weight: bold;
  overflow-wrap: anywhere;
}
</style>
