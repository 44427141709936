<template>
  <div class="container">
    <h1 v-if="project">{{ project.name }}</h1>
    <DemoWarningBanner />

    <div>
      <ul class="nav nav-tabs mb-2">
        <li class="nav-item">
          <a class="nav-link" :class="{ active: showingModelsTab }" href="#" @click.prevent="onModelsTab">3D models ({{ modelsList.length }})</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" :class="{ active: showingSessionsTab }" href="#" @click.prevent="onSessionsTab">Presentation sessions ({{ sessionsList.length }})</a>
        </li>
      </ul>
    </div>

    <div class="row">
      <div class="col-md mb-4">
        <div>
          <ListCard v-if="showingModelsTab"
            title="3D models" icon="cubes"
            loadMessage="Loading recent 3D models"
            addMessage="You don't have any 3D models yet. Create your first model."
            v-bind:showAddHeader="true" v-bind:showAddBody="false" v-bind:showSubtitle="false"
            v-bind:loading="loading"
            v-bind:items="modelsList"
            @itemclicked="on3DModelItemClicked"
            @addclicked="on3DModelAddClicked" />

          <ListCard v-if="showingSessionsTab"
            title="Presentation sessions" icon="chalkboard-teacher"
            loadMessage="Loading presentation sessions"
            addMessage="You don't have any sessions yet. Create your first session."
            v-bind:showAddHeader="true" v-bind:showEdit="false" v-bind:showSubtitle="false"
            v-bind:loading="loading"
            v-bind:items="sessionsList"
            @itemclicked="onSessionItemClicked"
            @addclicked="onSessionAddClicked"
            @editclicked="onSessionEditClicked" />
        </div>
      </div>

      <div class="col-md mb-4">
        <PresentationSessionForm v-if="showingSessionsTab"
          v-bind:item="session"
          @savedone="onSessionSaveDone"
          @deletedone="onSessionDeleteDone" />

        <ThreeDimensionalModelForm v-if="showingModelsTab"
          v-bind:item="model"
          @savedone="on3DModelSaveDone"
          @deletedone="on3DModelDeleteDone" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useStore } from '@/store';
import { ActionTypes } from '@/store/action-types';
import ListCard from '@/components/ListCard.vue';
import { ListCardItem } from '@/models/list-card-item';
import { ProjectModel } from '@/models/project-model';
import { PresentationSessionModel } from '@/models/presentation-session-model';
import { ThreeDimensionalModel } from '@/models/three-dimensional-model';
import PresentationSessionForm from '@/components/PresentationSessionForm.vue';
import PresentationSessionService from '@/services/presentation-sessions-service';
import ThreeDimensionalModelForm from '@/components/ThreeDimensionalModelForm.vue';
import ThreeDimensionalModelService from '@/services/three-dimensional-model-service';
import { setFocus } from '@/services/dom-tools';
import DemoWarningBanner from '@/components/DemoWarningBanner.vue';

export default defineComponent({
  name: 'ProjectDetails',
  components: {
    ListCard,
    PresentationSessionForm,
    ThreeDimensionalModelForm,
    DemoWarningBanner,
  },

  setup() {
    const store = useStore();
    return { store };
  },

  mounted() {
    this.store.dispatch(ActionTypes.LOAD_ALL_DATA).then(
      () => this.onDataLoaded(),
      () => this.$router.push({ name: "Signin" })
    );
  },

  data: () => ({
    project: undefined as ProjectModel | undefined,
    session: PresentationSessionService.createNewSession(),
    model: ThreeDimensionalModelService.createNewModel(),

    currentTab: 'models',
  }),

  computed: {
    loading(): boolean { return this.store.state.loadingData; },
    modelsList(): ListCardItem[] { return this.store.getters.modelsListForProjectId(this.projectId); },
    sessionsList(): ListCardItem[] { return this.store.getters.sessionsListForProjectId(this.projectId); },
    showingModelsTab(): boolean { return this.currentTab === 'models'; },
    showingSessionsTab(): boolean { return this.currentTab === 'sessions'; },
    projectId(): number { return this.project ? this.project.id : 0; },
  },

  methods: {
    on3DModelItemClicked(item: ListCardItem) {
      this.model = this.getModelById(+item.id);
      setFocus('name');
    },

    on3DModelAddClicked() {
      this.model = ThreeDimensionalModelService.createNewModel(this.projectId);
      setFocus('name');
    },

    on3DModelSaveDone(item: ThreeDimensionalModel) {
      this.model = item;
    },

    on3DModelDeleteDone() {
      this.on3DModelAddClicked();
    },

    onSessionItemClicked(item: ListCardItem) {
      // TODO: Will navigate to session notes instead.
      this.session = this.getSessionById(+item.id);
      setFocus('title');
    },

    onSessionAddClicked() {
      this.session = PresentationSessionService.createNewSession(this.projectId);
      setFocus('title');
    },

    onSessionEditClicked(item: ListCardItem) {
      this.session = this.getSessionById(+item.id);
    },

    onSessionSaveDone(item: PresentationSessionModel) {
      this.session = item;
    },

    onSessionDeleteDone() {
      this.onSessionAddClicked();
    },

    onModelsTab(): void {
      this.currentTab = 'models';
      this.initForms();
      setFocus('name');
    },
    
    onSessionsTab(): void {
      this.currentTab = 'sessions';
      this.initForms();
      setFocus('title');
    },
    
    onDataLoaded(): void {
      const projectId = +this.$route.params.id;

      if (projectId) {
        this.project = this.getProjectById(projectId);
        this.initForms();

        if (this.$route.query.modelId) {
          this.model = this.getModelById(+this.$route.query.modelId);
          setFocus('name');
        }
        else if (this.$route.query.sessionId) {
          this.currentTab = 'sessions';
          this.session = this.getSessionById(+this.$route.query.sessionId);
          setFocus('title');
        }
        else
          setFocus('name');
      }
    },

    initForms(): void {
      this.model = ThreeDimensionalModelService.createNewModel(this.projectId);
      this.session = PresentationSessionService.createNewSession(this.projectId);
    },

    getProjectById(id: number): ProjectModel {
      return this.store.getters.getProjectById(id);
    },

    getSessionById(id: number): PresentationSessionModel {
      return this.store.getters.getSessionById(id);
    },

    getModelById(id: number): ThreeDimensionalModel {
      return this.store.getters.getModelById(id);
    },
  },
});
</script>
