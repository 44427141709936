<template>
  <div class="wrapper" :style="{ width: width + 'px' }">
    <div class="label">{{ progress }}<span class="smaller">%</span></div>
    <svg :height="width" :width="width">
      <circle
        stroke="#5A73B9"
        fill="transparent"
        :stroke-dasharray="circumference + ' ' + circumference"
        :style="{ strokeDashoffset }"
        :stroke-width="stroke"
        :r="normalizedRadius"
        :cx="radius"
        :cy="radius"
      />
    </svg>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'CircularProgress',
  props: {
    progress: {
      type: Number,
      required: false,
      default: 0
    },
    radius: {
      type: Number,
      required: false,
      default: 75
    },
    stroke: {
      type: Number,
      required: false,
      default: 10
    },
  },

  data() {
    const normalizedRadius = this.radius - this.stroke * 2;
    const circumference = normalizedRadius * 2 * Math.PI;

    return {
      normalizedRadius,
      circumference
    };
  },

  computed: {
    strokeDashoffset(): number {
      return this.circumference - this.progress / 100 * this.circumference;
    },

    width(): number {
      return this.radius * 2;
    }
  },
});
</script>

<style lang="scss" scoped>
.wrapper {
  position: relative;
}

.label {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  font-size: 1.5rem;
  font-weight: bold;

  .smaller {
    color: #919292;
    font-size: .45em;
    padding-bottom: 6px;
  }
}
</style>
