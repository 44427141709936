import { ImageInfo, ImageSize } from '@/models/image-info';

export function base64ToBlob(base64Data: string, contentType = '', sliceSize = 512) {
  const byteCharacters = atob(base64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);

    for (let i = 0; i < slice.length; i++)
      byteNumbers[i] = slice.charCodeAt(i);

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, {type: contentType});

  return blob;
}

export function blobToBase64(blob: Blob): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = () => {
      let base64 = reader.result as string;

      // Remove header.
      base64 = base64.substring(base64.indexOf(',') + 1);

      resolve(base64);
    };

    reader.onerror = (error) => {
      reject(error);
    };

    reader.readAsDataURL(blob);
  });
}

function getImageSizeFromBlob(blob: Blob): Promise<ImageSize> {
  return new Promise((resolve, reject) => {
      const imageUrl = URL.createObjectURL(blob);
      const img = new Image;

      img.onload = () => {
        URL.revokeObjectURL(imageUrl);
        resolve({
          height: img.height,
          width: img.width
        });
      };

      img.onerror = () => {
        URL.revokeObjectURL(imageUrl);
        reject('Failed to load image.');
      };
  
      img.src = imageUrl;
  });
}

function getImageType(arrayBuffer: ArrayBuffer): string | undefined {
  const firstBytes = new DataView(arrayBuffer, 0, 5);
  const num1 = firstBytes.getUint8(0);
  const num2 = firstBytes.getUint8(1);
  const hex = num1.toString(16) + num2.toString(16);

  switch(hex){
    case '8950': return 'image/png';
    case '4749': return 'image/gif';
    case '424d': return 'image/bmp';
    case 'ffd8': return 'image/jpeg';
    default:
      return undefined;
  }
}

function getImageTypeFromBlob(blob: Blob): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = () => {
      if (reader.result)
        resolve(getImageType(reader.result as ArrayBuffer));
    };

    reader.onerror = (error) => {
      reject(error);
    };

    reader.readAsArrayBuffer(blob);
  });
}

export function getImageInfoFromBlob(blob: Blob): Promise<ImageInfo> {
  const imageSize = getImageSizeFromBlob(blob);
  const imageType = getImageTypeFromBlob(blob);

  return Promise.all([imageSize, imageType]).then(([size, type]) => {
    return {
      type: type,
      size: size
    }
  });
}

export function resizeImage(file: File, maxWidth: number, maxHeight: number, imageType?: string, imageQuality?: number): Promise<Blob> {
  return new Promise((resolve, reject) => {
      const image = new Image();
      image.src = URL.createObjectURL(file);

      image.onload = () => {
        const width = image.width;
        const height = image.height;
        
        if (width <= maxWidth && height <= maxHeight)
          resolve(file);

        let newWidth;
        let newHeight;

        if (width > height) {
          newHeight = height * (maxWidth / width);
          newWidth = maxWidth;
        }
        else {
          newWidth = width * (maxHeight / height);
          newHeight = maxHeight;
        }

        const canvas = document.createElement('canvas');
        canvas.width = newWidth;
        canvas.height = newHeight;

        const context = canvas.getContext('2d');

        if (context) {
          context.drawImage(image, 0, 0, newWidth, newHeight);

          const fileType = imageType ? imageType : file.type;
          const quality = imageQuality ? imageQuality : 0.8;

          canvas.toBlob(blob => {
            if (blob)
              resolve(blob);
            else
              reject;
          }, fileType, quality);
        }
      };

      image.onerror = reject;
  });
}
