
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'CircularProgress',
  props: {
    progress: {
      type: Number,
      required: false,
      default: 0
    },
    radius: {
      type: Number,
      required: false,
      default: 75
    },
    stroke: {
      type: Number,
      required: false,
      default: 10
    },
  },

  data() {
    const normalizedRadius = this.radius - this.stroke * 2;
    const circumference = normalizedRadius * 2 * Math.PI;

    return {
      normalizedRadius,
      circumference
    };
  },

  computed: {
    strokeDashoffset(): number {
      return this.circumference - this.progress / 100 * this.circumference;
    },

    width(): number {
      return this.radius * 2;
    }
  },
});
