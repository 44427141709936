<template>
  <div>
    <ModelUploadDialog v-bind:model="item" />

    <MessageBox
      id="confirmDeleteModel"
      buttons="DeleteCancel"
      title="Confirm deletion"
      message="Are you sure you want to delete the 3D model?"
      @validate="onDeleteValidate" />

    <div class="card">
      <div class="card-header">
        <span v-if="editMode">Edit 3D model</span>
        <span v-else>Add 3D model</span>
      </div>
      <div class="card-body">
        <form>
          <div class="form-group">
            <label for="project">Project</label>
            <div v-if="item.projectId > 0" class="d-flex align-items-center" style="min-height: 38px;"><strong>{{ projectName }}</strong></div>
            <select v-else v-model="projectId" class="form-control" id="project">
              <option v-for="project in projectsList" v-bind:key="project.id" :value="project.id">
                {{ project.title }}
              </option>
            </select>
          </div>
          <div class="form-group">
            <label for="name">Name</label>
            <input v-model.trim="name" type="text" class="form-control" id="name" placeholder="Enter name">
          </div>
          <div class="form-group">
            <label for="mainFileType">Description</label>
            <textarea v-model.trim="description" class="form-control" id="description" rows="3"></textarea>
          </div>

          <div class="form-group">
            <label for="thumbnailFileSelect">Thumbnail</label>
            <div class="d-flex justify-content-center" @drop="onDropPicture" @dragover="onDragOverPicture">
              <img v-if="thumbnailUrl" v-bind:src="thumbnailUrl" @click="onSelectThumbnailFile" class="thumbnail" :style="thumbnailStyle" alt="Thumbnail">
              <div v-else class="upload-button" @click="onSelectThumbnailFile">
                <div class="d-flex justify-content-center"><font-awesome-icon icon="image" style="font-size: 3rem;" /></div>
                <div class="d-flex justify-content-center mt-2">Drop a picture, or click to select a picture</div>
                <div class="d-flex justify-content-center mt-2"><small class="text-muted">JPEG or PNG files. 512 ko maximum size.</small></div>
              </div>
            </div>
            <input id="thumbnailFileSelect" type="file" @change="onThumbailFileSelected" accept=".jpg, .jpeg, .png" style="display: none;">
          </div>
          <div class="form-group">
            <div class="d-flex justify-content-center">
              <label>{{ thumbnailInfo }}</label>
            </div>
          </div>

          <div v-if="item.mainFileType && item.mainFileType.length > 0" class="form-group">
            <label for="mainFileType">File type</label>
            <input :value="item.mainFileType" type="text" class="form-control" id="mainFileType" readonly="true">
          </div>
          <div v-if="fileSize.length > 0" class="form-group">
            <label for="mainFileSize">File size</label>
            <input :value="fileSize" type="text" class="form-control" id="mainFileSize" readonly="true">
          </div>

          <HttpExtendedResultAlert v-if="error" v-bind:result="error" />

          <div class="d-flex justify-content-between">
            <button type="submit" class="btn btn-primary" @click.prevent="onSave()" v-bind:disabled="saveDisabled">
              <font-awesome-icon v-if="loading" icon="spinner" spin />
              Save
            </button>
            <button v-if="editMode" type="button" class="btn btn-info" v-bind:disabled="loading" data-toggle="modal" data-target="#modelUploadModal" data-backdrop="static" id="uploadModelButton">Upload model</button>
            <button v-if="editMode" type="button" class="btn btn-danger" @click.prevent="onDelete()" v-bind:disabled="loading">Delete</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useStore } from '@/store';
import { ActionTypes } from '@/store/action-types';
import { ThreeDimensionalModel } from '@/models/three-dimensional-model';
import { ProjectModel } from '@/models/project-model';
import { ListCardItem } from '@/models/list-card-item';
import MessageBox from '@/components/MessageBox.vue';
import HttpExtendedResultAlert from '@/components/HttpExtendedResultAlert.vue';
import { HttpExtendedResult } from '@/services/http-extended-result';
import { getImageInfoFromBlob, blobToBase64, base64ToBlob, resizeImage } from '@/services/image-tools';
import { getSizeAsString } from '@/services/format-tools';
import ModelUploadDialog from '@/components/ModelUploadDialog.vue';
import { ImageInfo, ImageSizeCssClass } from '@/models/image-info';
import { clickElement } from '@/services/dom-tools';

export default defineComponent({
  name: 'ThreeDimensionalModelForm',
  components: {
    MessageBox,
    HttpExtendedResultAlert,
    ModelUploadDialog,
  },
  props: {
    item: {
      type: ThreeDimensionalModel,
      required: true
    },
  },

  setup() {
    const store = useStore();
    return { store };
  },

  beforeUnmount() {
    this.clearThumbnail();
  },

  data: () => ({
    projectId: 0,
    name: '',
    description: undefined as string | undefined,
    
    thumbnailUpdated: false,
    thumbnailBlob: undefined as Blob | undefined,
    thumbnailUrl: undefined as string | undefined,
    thumbnailInfo: undefined as string | undefined,
    imageInfo: undefined as ImageInfo | undefined,

    loading: false,
    error: undefined as HttpExtendedResult | undefined,
  }),

  watch: {
    'item': {
      handler: function(newItem: ThreeDimensionalModel) {
        this.clearThumbnail();
        this.thumbnailBlob = undefined;

        this.projectId = newItem.projectId;
        this.name = newItem.name;
        this.description = newItem.description;

        if (newItem.thumbnail && newItem.thumbnail.length > 0)
          this.loadThumbnailFromBlob(base64ToBlob(newItem.thumbnail));
        
        this.thumbnailUpdated = false;
        this.error = undefined;
      },
      //deep: true,
      immediate: true
    },
  },

  computed: {
    editMode(): boolean { return this.item.id > 0; },
    saveDisabled(): boolean { return this.loading || (this.item.name == this.name && this.item.description == this.description && !this.thumbnailUpdated); },
    projectsList(): ListCardItem[] { return this.store.getters.projectsList; },
    projectName(): string {
      const project = this.getProjectById(this.projectId);      
      return project ? project.name : '(select project first)';
    },
    fileSize(): string { return this.item.mainFileSize ? getSizeAsString(this.item.mainFileSize) : ''; },
    thumbnailStyle(): ImageSizeCssClass { return this.calculateThumbnailSize(); },
  },

  methods: {
    async onSave() {
      this.loading = true;
      this.error = undefined;

      let thumbnailBase64 = this.item.thumbnail;

      if (this.thumbnailBlob)
        thumbnailBase64 = await blobToBase64(this.thumbnailBlob);

      const payload = new ThreeDimensionalModel(
        this.item.id,
        this.projectId,
        this.name,
        this.description,
        this.item.mainFilePath,
        this.item.mainFileType,
        this.item.mainFileSize,
        thumbnailBase64);

      if (this.editMode)
        this.update(payload);
      else
        this.add(payload);
    },

    onDelete() {
      clickElement('confirmDeleteModelShowButton');
    },

    async onDeleteValidate() {
      this.loading = true;
      this.error = undefined;

      this.store.dispatch(ActionTypes.DELETE_THREE_DIMENSIONAL_MODEL, this.item.id).then(
        status => this.parseDeleteStatus(status),
        error => this.parseResponse(undefined, error));
    },

    add(payload: ThreeDimensionalModel) {
      this.store.dispatch(ActionTypes.ADD_THREE_DIMENSIONAL_MODEL, payload).then(
        model => this.parseResponse(model, undefined),
        error => this.parseResponse(undefined, error));
    },

    update(payload: ThreeDimensionalModel) {
      this.store.dispatch(ActionTypes.UPDATE_THREE_DIMENSIONAL_MODEL, payload).then(
        model => this.parseResponse(model, undefined),
        error => this.parseResponse(undefined, error));
    },

    parseResponse(model?: ThreeDimensionalModel, error?: HttpExtendedResult) {
      this.loading = false;
      this.error = error;

      if (model) {
        this.$emit('savedone', model);

        if (this.editMode == false)
          clickElement('uploadModelButton', 500);
      }
    },

    parseDeleteStatus(status: boolean) {
      this.loading = false;

      if (status) {
        this.$emit('deletedone');
      }
      else {
        const result: HttpExtendedResult = { status: 500, title: 'An unknown error occurred!', isCancel: false };
        this.parseResponse(undefined, result);
      }
    },

    getProjectById(id: number): ProjectModel {
      return this.store.getters.getProjectById(id);
    },

    onDropPicture(event: DragEvent) {
      // Prevent default behavior (prevent file from being opened).
      event.preventDefault();

      const data = (event.dataTransfer as DataTransfer);

      if (data.items) {
        for (let nItem = 0; nItem < data.items.length; nItem++) {
          if (data.items[nItem].kind === 'file') {
            const file = data.items[nItem].getAsFile();
            if (file && this.isSupportedPictureType(file)) {
              this.setSelectedPicture(file);
            }
          }
        }
      }
    },

    onDragOverPicture(event: Event) {
      // Prevent default behavior (prevent file from being opened).
      event.preventDefault();
    },

    isSupportedPictureType(file: File): boolean {
      const extension = file.name.substring(file.name.lastIndexOf('.') + 1).toLowerCase();
      return (extension === 'jpg' || extension === 'jpeg' || extension === 'png');
    },

    onSelectThumbnailFile() {
      clickElement('thumbnailFileSelect');
    },

    onThumbailFileSelected(event: Event) {
      const files = (event.target as HTMLInputElement).files;

      if (files && files.length > 0) {
        const file = files[0];
        this.setSelectedPicture(file);
        (document.getElementById('thumbnailFileSelect') as HTMLInputElement).value = '';
      }
    },

    setSelectedPicture(file: File) {
      if (file.type == 'image/jpeg' || file.type == 'image/png') {
        resizeImage(file, 600, 600, 'image/jpeg', 0.5).then(blob => {
          this.thumbnailBlob = blob;
          this.loadThumbnailFromBlob(blob);
          this.thumbnailUpdated = true;
        });
      }
      else {
        alert('You can only select JPEG or PNG image files.');
      }
    },

    loadThumbnailFromBlob(blob: Blob) {
      this.clearThumbnail();

      this.thumbnailUrl = URL.createObjectURL(blob);
      this.displayThumbnailInfo(blob);
    },

    displayThumbnailInfo(blob: Blob) {
      getImageInfoFromBlob(blob).then(imageInfo => {
        this.imageInfo = imageInfo;
        this.thumbnailInfo = imageInfo.size.width + ' x ' + imageInfo.size.height + ' | ' + getSizeAsString(blob.size) + ' | ' + imageInfo.type.split('/')[1];
      });
    },

    clearThumbnail() {
      if (this.thumbnailUrl)
        URL.revokeObjectURL(this.thumbnailUrl);

      this.thumbnailUrl = undefined;
      this.thumbnailInfo = undefined;
    },

    calculateThumbnailSize(): ImageSizeCssClass {
      const maxImageSize = 400;

      if (this.imageInfo) {
        if (this.imageInfo.size.height < this.imageInfo.size.width) {
          // Landscape image.
          const scale = maxImageSize / this.imageInfo.size.width;
          const height = this.imageInfo.size.height * scale;
          return { width: maxImageSize + 'px', height: height + 'px' };
        }
        else {
          // Portrait image.
          const scale = maxImageSize / this.imageInfo.size.height;
          const width = this.imageInfo.size.width * scale;
          return { width: width + 'px', height: maxImageSize + 'px' };
        }
      }

      return { width: maxImageSize + 'px', height: maxImageSize + 'px' };
    },
  },
});
</script>

<style lang="scss" scoped>
.upload-button {
  cursor: pointer;
  border-style: dashed;
  border-color: #aaa;
  padding: 1rem;
  border-radius: .5rem;
  border-width: 2px;
}

.thumbnail {
  cursor: pointer;
}
</style>
